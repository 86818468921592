import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {Notification} from '../../model/user/notification';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {
    modelNotifications: Notification[] = [];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public notificationsService: UserService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        // console.log('Notification:ngOnInit');
        this.notificationsService.notification.subscribe({
            next: data => {
                this.modelNotifications = data as Notification[];
                // this.loading.hide();
            }, error: err => super.onError(err)
        });
        this.notificationsService.getNotification();
    }


    actionNotification(model) {
        this.notificationsService.updateNotification(model.id).subscribe({
            next: data => {
                this.router.navigate(['/register/' + model.relationId]);
            }, error: err => super.onError(err)
        });
    }
}
