import {Active} from './active';
import {Opened} from './opened';
import {Month} from './month';
import {Request} from '../request/request';

export class Dashboard {

    active: Active;
    opened: Opened;
    months: Month;
    latest: Request[];
    avarageTimeDays: AvarageTimeDays[];

    constructor() {
        this.active = new Active();
        this.opened = new Opened();
        this.months = new Month();
        this.latest = [];
        this.avarageTimeDays = [];
    }
}

export class AvarageTimeDays {

    days: number;
    step: string;
    stepValue: number;

    constructor() {
    }
}
