import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import {EmitEvent, EventBusService, Events} from '../../service/event-bus.service';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    user: User;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService,
                private eventbus: EventBusService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {

        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }

    handlerSwitchLanguage(language) {
        this.switchLanguage(language);
        this.eventbus.emit(new EmitEvent(Events.actionLanguage));
        location.reload();
    }

    actionLogout() {
        localStorage.clear();
        const p = window.location.pathname.split('/');
        // const virtualPath = p.length >= 3 ? '/' + p[1] : '';
        const redirectUrl = window.location.origin +  '/logout';
        const logoutURL = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + redirectUrl;
        window.location.href = logoutURL;
    }
}
