import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from '../model/user/user';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardUser implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const data = localStorage.getItem('profile');
        if (data === 'administrator' || data === 'consult') {
            return true;
        } else {
            this.router.navigate(['/']);
        }
        return true;
    }
}
