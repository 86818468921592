import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterGenfarComponent} from "./register-genfar.component";

const routes: Routes = [
  {path: '', component: RegisterGenfarComponent},
  {path: ':id', component: RegisterGenfarComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterGenfarRoutingModule { }
