<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<div class="d-flex bg-warning align-items-center justify-content-between p-4">
    <h1 class="title ">{{'minhalista.title' | translate}}</h1>
    <span class="subTitle text-primary ml-2">{{'code400' | translate}}</span>

    <div *ngIf="profileExcel()">
        <a href="javascript:void(0)" (click)="actionExcel()"
           class="btn btn-outline-primary "
           type="button">{{'Exportar Excel' | translate}}</a>
    </div>
</div>

<div class="bg-gray w-100 pt-3 px-3 px-sm-5">
    <div class="row">
        <div class="col-md-10 pr-md-4">
            <div class="row">
                <div class="col-xxl-12">
                    <div class="row">
                        <div class="col-sm-6 col-xxxl-4">
                        <div class="input-material">
                            <select id="type" class="form-control" name="type" [(ngModel)]="modelFilter.flowType">
                                <option selected
                                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let d of listTypeArea" [value]="d.value">{{d.label}}</option>
                            </select>
                            <label for="type" class="mb-0 mr-3">Tipo de Projeto</label>
                        </div>
                        </div>
                        <div class="col-sm-6 col-xxxl-4">
                        <div class="input-material">
                            <input id="code400" class="form-control" type="text" [(ngModel)]="modelFilter.code_400"
                                   required/>
                            <label for="code">Código 400</label>
                        </div>
                        </div>
                        <div class="col-sm-6 col-xxxl-4">
                            <div class="input-material">
                                <select id="status" class="form-control" name="type" [(ngModel)]="modelFilter.status">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option *ngFor="let d of listStatus" [value]="d">{{handlerLanguage(d)}}</option>
                                </select>
                                <label for="status" class="mb-0 mr-3">Status</label>
                            </div>
                        </div>

                        <div class="col-sm-6 col-xxxl-4">
                            <div class="input-material">
                                <input id="name" class="form-control" type="text" [(ngModel)]="modelFilter.name"
                                       required/>
                                <label for="name">{{'minhalista.input1' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xxxl-4">
                            <div class="input-material">
                                <select id="stage" class="form-control" name="type" [(ngModel)]="modelFilter.step">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option *ngFor="let d of listStep" [value]="d">{{handlerLanguage(d)}}</option>
                                </select>
                                <label for="stage" class="mb-0 mr-3">{{'minhalista.input8.title' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xxxl-4">
                            <div class="input-material">
                                <input id="code" class="form-control" type="text" [(ngModel)]="modelFilter.code"
                                       required/>
                                <label for="code">{{'minhalista.input2' | translate}}</label>
                            </div>
                        </div>

                        <div class="col-sm-6 col-xxxl-4">
                            <div class="input-material">
                                <input id="start" class="form-control js-datepangepicker" type="text" required/>
                                <label for="start">{{'minhalista.input4' | translate}}</label>
                            </div>
                        </div>

                        <div class="col-sm-6 col-xxxl-4">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'general.pais' | translate"
                                        [settings]="dropdownSettings"
                                        [data]="dropdownList"
                                        [(ngModel)]="selectedItems"
                                        (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                >
                                </ng-multiselect-dropdown>
                                <label for="status" class="mb-0 mr-3">&nbsp;</label>
                            </div>
                        </div>
                        <!--                        <div class="col-sm-6 col-xxxl-4">-->
                        <!--                            <div class="input-material d-flex align-items-center">-->
                        <!--                                <div class="custom-control custom-switch w-100">-->
                        <!--                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="isChecked">-->
                        <!--                                    <label class="custom-control-label" for="customSwitch1" >{{'general.isInactive' | translate}}</label>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </div>-->

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2 border-left mb-4 pl-3 pl-md-4">
            <a href="javascript:void(0)" (click)="actionClear()"
               class="btn btn-block btn-outline-primary"
               type="button">{{'minhalista.limpar' | translate}}</a>
            <a href="javascript:void(0)" (click)="actionSearch()"
               class="btn btn-block text-warning btn-primary"
               type="button">{{'minhalista.buscar' | translate}}</a>
        </div>
    </div>

</div>
<div class="mt-4" style="max-width: 1484px; margin: auto;">
    <div class="text-center mt-5" *ngIf="isNullOrUndefined(modelResult.results)">
        <span>Sem Resultados</span>
    </div>
    <div *ngFor="let r of modelResult.results |
            paginate: {
             itemsPerPage: modelResult.pageSize,
             currentPage: modelResult.currentPage,
             totalItems: modelResult.totalCount}" class="row mx-5 py-4 border-bottom">
        <div class="col-11 pr-0 cursor-pointer" [routerLink]="getLinkRequestByType(r.flowType) + r.id">
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="row">
                        <h3 class="dash_card_title">{{getNameRequestByType(r.flowType)}}</h3>
                    </div>
                    <div class="row">
                        <span class="dash_card_title size-xm">
                            {{r.name}}
                        </span>
                    </div>
                    <!--                    <div class="row">-->
                    <!--                        <span class="d-flex align-items-center">-->
                    <!--                            <svg-icon class="svg svg-xs mr-2"-->
                    <!--                                      [ngClass]="{'svg-danger' : r.status == 'disapprove', 'svg-success' : r.status == 'approve'}"-->
                    <!--                                      [src]="'assets/svg/' + getIconByStatus(r.status) + '.svg'"></svg-icon>-->
                    <!--                            {{'general.codigomodelo' | translate}}: {{r.code}}-->
                    <!--                        </span>-->
                    <!--                    </div>-->
                </div>
                <div class="col-12 col-md-3 request_steps">
                    <div class="d-flex justify-content-center">
                        <span class="unificado mb-1" *ngIf="r.type !== 'simple'">
                            <svg-icon src="assets/svg/unified.svg"></svg-icon>
                            {{'dashboard.card2.card.unificado' | translate}}
                        </span>
                        <span class="unificado_simples mb-1" *ngIf="r.type == 'simple'">
                                <svg-icon src="assets/svg/simple.svg"></svg-icon>
                            {{'dashboard.card2.card.simples' | translate}}
                            </span>
                    </div>
                    <app-country-flags [list]="r.countryNotifications" [listAdd]="r.countryAdditional"
                                       [listAddGroup]="r.group"></app-country-flags>
                </div>
                <div *ngIf="r.status == 'open'" class="col-12 col-md-5 request_steps">
                    <div class="d-flex justify-content-between">

                        <span class="position-relative request_steps_item_number" *ngFor="let i of (r.flowType === 'new-business' ? stepsArray[1] : stepsArray[0])"
                              [ngClass]="{'active':r.step > i - 1 , 'current': r.step == i - 1}">
                            {{i}}
                            <span style="left: 0;top: 22px;" class="position-absolute font-weight-normal text-primary">
                                <span *ngIf="r.bulk === 1 && i === 4">BULK</span>
                                {{r.history[i - 1]?.createdAt | date: 'dd/MM'}}
                            </span>
                        </span>
                    </div>
                    <div class="progress request_steps_barFull">
                        <div class="progress-bar request_steps_bar"
                             role="progressbar"
                             style="{{handlerStepBar(r.step + 1, r.flowType === 'new-business')}}" aria-valuenow="15"
                             aria-valuemin="0" aria-valuemax="200">
                        </div>
                    </div>
                </div>

                <div *ngIf="r.status == 'archived' " class="col-12 col-md-5 request_steps">
                    <div class="d-flex justify-content-between">

                        <span class="position-relative request_steps_item_number" *ngFor="let i of (r.flowType === 'new-business' ? stepsArray[1] : stepsArray[0])"
                              [ngClass]="{'active':r.step > i  , 'current': r.step == i }">
                            <span style="left: 0;top: 22px;" class="position-absolute font-weight-normal text-primary">
                                <span *ngIf="r.bulk === 1 && i === 4">BULK</span>
                                {{r.history[i - 1]?.createdAt | date: 'dd/MM'}}
                            </span>
                        </span>
                    </div>
                    <div class="progress request_steps_barFull">
                        <div class="progress-bar request_steps_bar"
                             role="progressbar"
                             style="{{handlerStepBar(r.step, r.flowType === 'new-business')}}" aria-valuenow="15"
                             aria-valuemin="0" aria-valuemax="200">
                        </div>
                    </div>
                </div>
                <div *ngIf="r.status == 'disapprove'" class="col-12 col-md-5 request_steps">
                    <div class="row">
                        <div class="col"></div>
                        <div class="d-flex align-items-center ml-auto">
                            <div class="bg-danger text-white rounded d-flex justify-content-center px-2">
                                <svg-icon src="assets/svg/close.svg"></svg-icon>
                                <span class="ml-1">{{'minhalista.reprovado' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="r.status == 'approve'" class="col-12 col-md-5 request_steps">
                    <div class="row">
                        <div class="col">
                            <span>{{'general.codeSAPCreate' | translate}}:<br></span>
                            <strong>{{r.codeSap}}</strong>
                        </div>
                        <div class="d-flex align-items-center ml-auto">
                            <div class="bg-success text-white rounded d-flex justify-content-center px-2">
                                <svg-icon src="assets/svg/check.svg"></svg-icon>
                                <span class="ml-1">{{'minhalista.concluido' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!--        <div class="col-1 px-0" *ngIf="r.historyAudits?.length > 0">-->
        <!--            <div class="row">-->
        <!--                <div class="col-12 text-center">-->
        <!--                    <a href="javascript:void('');" class="svg svg-sm" (click)="actionHistoryAudits(r.historyAudits)">-->
        <!--                        <svg-icon class="svg size-md" src="assets/svg/files.svg"></svg-icon>-->
        <!--                    </a>-->
        <!--                    Historico-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="col-1 px-0" *ngIf="r.status === 'archived' ">
            <div class="row">
                <div class="col-12 text-center">
                    <svg-icon src="assets/svg/inbox.svg"></svg-icon>
                    {{'general.archived' | translate}}
                </div>

            </div>
        </div>
        <!--        <div class="col mx-sm-3 d-flex justify-content-end align-items-center">-->
        <!--            <svg-icon src="assets/svg/menu.svg"></svg-icon>-->
        <!--        </div>-->
    </div>

    <div class="text-center my-3">
        <pagination-controls
                [previousLabel]="labels.previousLabel"
                [nextLabel]="labels.nextLabel"
                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
</div>


<!--Modal Historic Audits -->
<div class="modal-custom modal fade" id="modalHistoryDetail" data-keyboard="false" data-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="font-weight-bold text-primary">Histórico</h3>
                <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">X</span>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row flex-column">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table table-custom table-striped" border="0" cellpadding="0" cellspacing="0">
                                <tr>
                                    <th>{{'history.date' | translate}}</th>
                                    <th>{{'history.responsible' | translate}}</th>
                                    <th>{{'history.reprobate' | translate}}</th>
                                    <th>{{'history.reason' | translate}}</th>
                                    <th>{{'history.realize' | translate}}</th>
                                </tr>
                                <tr *ngFor="let x of modelHistoryAudits">
                                    <td>{{x.createdAt | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{x.auditBy?.name}}</td>
                                    <td>{{x.stepFrom}} - {{getNameStepByNumber(x.stepFrom)}}</td>
                                    <td>{{x.motive}}</td>
                                    <td>{{x.step + 1}} - {{convertToNumber(x.step)}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
