import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../base/base.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../service/user.service';
import {Token} from '../../model/user/token';

declare var $: any;

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent extends BaseComponent implements OnInit {

  modelEmailMock = '';
  model: any = {code: '', email: '', redirectUri: window.location.protocol + '//' + window.location.host + window.location.pathname};

  token = '';
  error = '';

  constructor(public router: Router,
              public loading: NgxSpinnerService,
              public translate: TranslateService,
              public service: UserService,
              public route: ActivatedRoute) {
    super(router, loading, translate);
  }

  ngOnInit(): void {
    if (environment.isLoginMock) {
      this.showModal('modalLoginEmail');
      return;
    }

    this.loading.show();
    this.route.queryParams.subscribe(data => {
      if (data.error != null) {
        this.error = data.error_description;
        return;
      }
      if (data.code != null) {
        this.model.code = data.code;
        this.onLogin();
        return;
      }
      this.redirectAuth();
    });
  }

  onLogin(): void {
    this.service.getAuthenticate({
      code: this.model.code,
      email: this.model.email,
      isLocal: environment.isLocal
    }).subscribe({
      next: (result) => {
        const token = result as Token;
        this.setToken(token.authorizationToken);
        this.service.updateUser(token.user);
        this.loading.hide();
        this.router.navigate(['/']);
      }, error: err => super.onError(err)
    });
  }

  redirectAuth() {
    const clientId = environment.client_id;
    const tenantId = environment.tenant_id;
    const p = window.location.pathname.split('/');
    const virtualPath = p.length >= 3 ? '/' + p[1] : '';
    const redirectUrl = window.location.origin + virtualPath + '/authenticate';
    const authUrl = 'https://login.microsoftonline.com/' + tenantId + '/oauth2/v2.0/authorize?client_id=' + clientId + '&response_type=code&redirect_uri=' + redirectUrl + '&response_mode=query&scope=openid%20offline_access%20https://graph.microsoft.com/mail.read&state=12345';
    window.location.href = authUrl;
  }

  onLoginMock() {
    const arrValidateFields = [
      {value: this.modelEmailMock, text: `${this.translate.instant('email')}<br>`}
    ];
    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
      return;
    }
    this.closeModal('modalLoginEmail');
    this.model.email = this.modelEmailMock;
    this.onLogin();
  }

  closeLoginMock() {
    this.closeModal('modalLoginEmail');
    this.router.navigate(['/logout']);
  }

}
