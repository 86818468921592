import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../service/request.service';
import {Notification} from '../../model/user/notification';
import {RequestFilter} from '../../model/request/request-filter';
import {RequestResult} from '../../model/request/request-result';
import {Enum} from '../../model/enum';
import {EnumService} from '../../service/enum.service';
import {UserService} from '../../service/user.service';
import {EventBusService, Events} from '../../service/event-bus.service';
import {Subscription} from 'rxjs';
import {RequestHistoryAudits} from '../../model/request/request-unifications';
import {User} from '../../model/user/user';
import {take} from "rxjs/operators";

declare var moment: any;
declare var $: any;

@Component({
    selector: 'app-my-list',
    templateUrl: './my-list.component.html',
    styleUrls: ['./my-list.component.scss']
})
export class MyListComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};

    modelFilter: RequestFilter = new RequestFilter();
    modelResult: RequestResult = new RequestResult();
    listStatus = ['open', 'approve', 'disapprove'];
    listTypeArea = [];
    listStep = [1, 2, 3, 4, 5];
    listChannel: Enum[] = [];
    listDispatched: Enum[] = [];
    listCountry: Enum[] = [];
    currentPage = 1;
    isChecked: boolean;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    eventbusSub: Subscription;
    modelHistoryAudits = [];

    user: User;
    countries: any = [];

    stepsArray: any = [[1, 2, 3, 4, 5], [1, 2, 3, 4, 5, 6]];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public enumService: EnumService,
                public requestService: RequestService,
                public userService: UserService,
                private eventbus: EventBusService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.isChecked = false;
        this.actionChannel();
        this.actionCountry();
        this.actionDispatched();
        this.eventbusSub = this.eventbus.on(Events.actionLanguage, () => this.handlerEnums());
        this.userService.$countries.subscribe({
            next: data => {
                if (data.length === 0) { return; }
                this.countries = data as any[];
                console.log('atualizado na lista');
                console.log(this.countries);
            }, error: err => super.onError(err)
        });

        this.requestService.getAllCountries().subscribe({
            next: data => {
                this.countries = data as any[];
                this.userService.updateCountries(this.countries);
            }, error: err => super.onError(err)
        });

        this.translate.get('novosNegocios').subscribe((text: string) => {
            this.listTypeArea = [
                {value: 'pmoi', label: this.translate.instant('requestPmoi')},
                {value: 'pmoint', label: this.translate.instant('requestPmoint')},
                {value: 'new-business', label: this.translate.instant('novosNegocios')},
                {value: 'genfar', label: 'GENFAR'},
            ];
        });
    }

    ngAfterViewInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.profileExcel();
            }, error: err => super.onError(err)
        });
        this.setDatePicker();
        this.activeMyList();


    }

    ngOnDestroy(): void {
        this.eventbusSub.unsubscribe();
    }

    setDropDown() {
        this.dropdownList = this.listCountry;
        this.selectedItems = [];
        this.dropdownSettings = {
            singleSelection: false,
            searchPlaceholderText: 'Buscar',
            idField: 'name',
            textField: 'description',
            selectAllText: 'Marcar todos',
            unSelectAllText: 'Desmarcar todos',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }

    onItemSelect(item: any) {
        console.log(item);
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    activeMyList() {
        this.loading.show();
        this.modelFilter.isInactive = this.isChecked;
        this.requestService.search(this.modelFilter, 1).subscribe({
            next: data => {
                this.modelResult = data as RequestResult;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
        this.userService.getNotification();
    }

    handlerEnums() {
        this.actionChannel();
        this.actionCountry();
        this.actionDispatched();
    }

    pageChanged(event) {
        this.currentPage = event;
        this.actionSearch();
    }

    handlerLanguage(model) {
        switch (model) {
            case 'open':
                if (this.translate.currentLang === 'pt') {
                    return 'Aberto';
                } else {
                    return 'En processo';
                }
            case 'approve':
                if (this.translate.currentLang === 'pt') {
                    return 'Aprovado';
                } else {
                    return 'Concluido';
                }
            case 'disapprove':
                if (this.translate.currentLang === 'pt') {
                    return 'Reprovado';
                } else {
                    return 'Reprobado';
                }
            case 'archived':
                if (this.translate.currentLang === 'pt') {
                    return 'Inativo';
                } else {
                    return 'Inactivo';
                }
            case 1:
                if (this.translate.currentLang === 'pt') {
                    return 'Comercial';
                } else {
                    return 'Comercial';
                }
            case 2:
                if (this.translate.currentLang === 'pt') {
                    return 'AR Local';
                } else {
                    return 'AR Local';
                }
            case 3:
                if (this.translate.currentLang === 'pt') {
                    return 'AR Internacional';
                } else {
                    return 'AR Internacional';
                }
            case 4:
                if (this.translate.currentLang === 'pt') {
                    return 'Artes';
                } else {
                    return 'Artes';
                }
            case 5:
                if (this.translate.currentLang === 'pt') {
                    return 'Cadastro';
                } else {
                    return 'Catastro';
                }
        }
    }

    actionChannel() {
        this.enumService.getEnum('channel').subscribe({
            next: data => {
                this.listChannel = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    actionCountry() {
        this.enumService.getEnum('country').subscribe({
            next: data => {
                this.listCountry = data as Enum[];
                this.setDropDown();
            }, error: err => super.onError(err)
        });
    }

    actionDispatched() {
        this.enumService.getEnum('dispatched').subscribe({
            next: data => {
                this.listDispatched = data as Enum[];
            }, error: err => super.onError(err)
        });
    }

    setDatePicker() {
        const selfThis = this;
        const el = $('.js-datepangepicker');
        this.modelFilter.start = null;
        this.modelFilter.end = null;
        el.daterangepicker({
            startDate: moment().subtract(15, 'days'),
            autoUpdateInput: false,
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            // console.log(start.utcOffset(0, true).format());
            // console.log(end.utcOffset(0, true).format());
            this.modelFilter.start = start.utcOffset(0, true).format();
            this.modelFilter.end = end.utcOffset(0, true).format();
        });

        el.on('blur', function() {
            if ($(this).val().length === 0) {
                $(this).val('');
                selfThis.modelFilter.start = null;
                selfThis.modelFilter.end = null;
            }
        });


        el.on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        });

        el.on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
        });

    }

    actionSearch() {
        this.modelFilter.countries = [];
        if (this.selectedItems.length > 0) {
            this.selectedItems.map((x) => {
                // @ts-ignore
                this.modelFilter.countries.push(x.name);
            });
        }
        this.modelFilter.isInactive = this.isChecked;
        this.loading.show();
        this.requestService.search(this.modelFilter, this.currentPage).subscribe({
            next: data => {
                this.modelResult = data as RequestResult;
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    profileExcel() {
        switch (this.user?.profile) {
            case 'consult':
                return true;
            case 'commercial':
                return true;
            case 'ar_inter':
                return true;
            case 'ar_local':
                return true;
            case 'arts':
                return true;
            case 'register':
                return true;
            case 'administrator':
                return true;
            default:
                return true;
        }
    }

    actionExcel() {
        this.requestService.getExcelUser(this.modelFilter).subscribe({
            next: data => {
                this.downloadHandlerFile(data, 'Latam');
            }, error: err => super.onError(err)
        });
    }

    downloadHandlerFile(e: any, fileName: any) {
        const downLoad = $('#btnDownload');
        downLoad.attr('href', e);
        downLoad.attr('download', fileName + '_' + moment().format('DD-MM-YYYY'));
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    actionClear() {
        this.isChecked = false;
        this.modelFilter = new RequestFilter();
        this.selectedItems = [];
        this.setDatePicker();
        this.actionSearch();
    }

    actionHistoryAudits(historyAudits: RequestHistoryAudits[]) {
        this.modelHistoryAudits = historyAudits;
        $('#modalHistoryDetail').modal('show');
    }
}
