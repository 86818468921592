export class Active {

    step: string;
    value: string;
    stepValue: number;

    constructor() {
    }
}

