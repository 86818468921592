<ngx-spinner
  bdColor="rgba(3,46,88,1)"
  size="medium"
  color="#fff"
  type="ball-spin"
>
</ngx-spinner>

<app-header></app-header>

<div class="app-body">

  <app-sidebar></app-sidebar>
  <div class="main px-0 pb-0">
    <router-outlet></router-outlet>
  </div>

  <!--Modal-->
  <div class="modal-custom modal fade" id="modalNoteResume" data-keyboard="false"
       data-backdrop="static" role="dialog"
       aria-labelledby="modalNoteResumeLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body modal-body--small">
          <div class="row flex-column">
            <div class="text-center mx-auto">
              <svg-icon src="assets/svg/confirm.svg"></svg-icon>
            </div>
            <div class="text-center mx-auto font-weight-bold text-primary mt-5 size-xl"
                 style="max-width: 369px;line-height: 0.9;">
              {{'modalnoteresume.sucesso' | translate}}
            </div>
            <div class="text-center mx-auto size-xm text-gray-text mt-4" style="max-width: 404px;">
              {{'modalnoteresume.responsavel' | translate}}
            </div>
            <div class="mx-auto">
              <a href="javascript:$('#modalNoteResume').modal('hide'); void('');"
                 class="btn btn-block btn-warning mt-5"
                 type="button">{{'modalnoteresume.fechar' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

