export class User {
  id: string;
  name: string;
  email: string;
  photo: any;
  country: string;
  profile: string;
  status: string;
  isCeam: boolean;

  constructor() {
  }
}
