export class UserFilter {
  keyword: string;
  profile: string;
  country: string;

  constructor() {
    this.profile = '';
    this.country = '';
  }
}
