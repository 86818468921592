export class RequestFilter {

    name: string;
    code: string;
    code_400: string;
    area_400: string;
    status: string;
    start: Date;
    end: Date;
    channel: string;
    step: number;
    dipatchedBy: string;
    countries: [];
    isInactive: boolean;
    flowType: string;

    constructor() {
        this.status = null;
        this.channel = null;
        this.dipatchedBy = null;
        this.step = null;
        this.countries = [];
    }
}
