import {User} from './user';

export class UserResult {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: boolean;
  nextPage: boolean;
  results: User[];

  constructor() {
    this.results = [];
  }
}
